import { APIFilter } from '@/utils/api'

export default {
  async selectPresencia (Vue, idpresencia) {
    const apiFilter = new APIFilter()
    apiFilter.addExact('idpresencia', idpresencia)
    const resp = await Vue.$api.call('presencia.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  }
}
